/* -------------- Media Query for Large screens ----------------  */

@media screen and (min-width: 1023px) {
  .section-center {
    width: clamp(60%, 70%, 85%);
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-left: 15vw;
    min-height: 460px;
    padding-bottom: 3em;
  }

  article {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    bottom: 0;
    padding-top: 0.5em;
    transition: var(--transition);
  }

  .slider-title {
    padding-top: 1em;
  }
  /* --------- buttons ------------ */
  .prev,
  .next {
    position: absolute;
    border-color: transparent;
    font-size: x-large;
    color: var(--clr-white);
    background: var(--clr-grey-5);
    cursor: pointer;
    font-weight: 700;
    padding-left: 1em;
    padding-right: 1em;
    top: 0;
    bottom: 0;
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  button {
    opacity: 0.2;
  }

  button:hover {
    color: rgb(0, 195, 255);

    opacity: 2;
  }

  /* ---------------- Animation --------------------- */

  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }

  article.lastSlide {
    transform: translateX(-100%);
  }

  article.nextSlide {
    transform: translateX(100%);
  }

  /* ------------ Inner Copy ----------------- */
  .ref-text {
    padding-left: 5em;
    padding-right: 5em;
    font-weight: 400;
  }

  .ref-name {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1em;
    margin-bottom: -1em;
  }
}
/* -----------------  Media Query for small screens ------------------- */
@media screen and (max-width: 1022px) {
  .section-center {
    width: 100%;
    text-align: center;
    min-height: 400px;
    padding-bottom: 3em;
  }

  .bg-color-slider {
    background-color: white;
    border-right: 5px black solid;
    border-bottom: 5px black solid;
    border-top: 2px black solid;
    border-left: 2px black solid;
    margin-bottom: 1.5em;
    border-radius: 10px;
  }

  article {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    bottom: 0;
    transition: var(--transition);
    margin-left: 3em;
    margin-right: 3em;
  }

  /* ------------ Inner copy ----------- */
  .slider-title {
    padding-top: 1em;
    padding-bottom: 20px;
  }

  .ref-text {
    padding-left: 5em;
    padding-right: 5em;
    font-weight: 400;
    line-height: 23px;
    padding-top: 1em;
  }

  .ref-name {
    font-size: 1rem;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 3em;
    padding-right: 3em;
  }

  /* ----------- buttons ------------ */
  .prev,
  .next {
    display: none;
  }
}

@media screen and (max-width: 660px) {
  .ref-text {
    padding-left: 1em;
    padding-right: 1em;
  }

  .ref-name {
    padding-left: 1em;
    padding-right: 1em;
  }
}
