.contact-Container {
  width: 100vw;
  min-height: 600px;
  background-color: rgb(34, 34, 34);
  color: white;
  position: relative;
}

.contact-sub-container {
  height: 500px;
  /* margin-bottom: 60px;
  margin-top: 60px; */
  width: 70vw;
  bottom: 10%;
  top: 10%;
  position: absolute;
  display: flex;
  right: 0;
  left: 15%;
  /* background-color: red; */
}

.contact-Copy {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  margin-left: 5em;
}
/* ---------- form fields ---------- */
.form-Section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 30em;
  /* margin-right: 0; */
}

textarea {
  height: 100px;
  border-radius: 10px;
  margin-top: 0.5em;
  margin-bottom: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  width: 25em;
}

input {
  border-radius: 10px;
  border: none;
  height: 40px;
  margin-bottom: 1.2em;
  margin-top: 0.5em;
  width: 25em;
}

#submit-btn {
  width: 5em;
  right: 0;
  cursor: pointer;
  font-weight: 600;
}

#submit-btn:hover {
  width: 5em;
  right: 0;
  cursor: pointer;
  background-color: rgb(0, 195, 255);
}
/* ------------- confirm message ---------------- */
.confirm-m {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  width: 20em;
  border: 2px solid aqua;
  padding: 40px;
  text-align: center;
}

.visible {
  display: initial;
}

.hidden {
  display: none;
}

/* ---------------- Media query for small screen ---------------- */
@media screen and (max-width: 1400px) {
  .contact-Copy {
    width: 100%;
    right: 0;
    align-items: center;
    padding-bottom: 420px;
    margin-left: 0em;
    text-align: center;
  }
  /* ---------- Form fields ---------- */
  .form-Section {
    width: 100%;
    top: none;
    bottom: none;
    left: 0;
    align-items: center;
    padding-top: 150px;
    /* border: 2px solid white; */
  }

  input {
    width: 20em;
  }

  textarea {
    width: 25em;
  }
  .confirm-m {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 20em;
    border: 2px solid aqua;
    padding: 40px;
    text-align: center;
  }
  .visible {
    display: initial;
  }

  .hidden {
    display: none;
  }

  #submit-btn {
    justify-content: center;
  }
}
