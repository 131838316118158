* {
  box-sizing: border-box;
  /* margin: 0 auto; */
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

body,
html {
  font-family: 'Nunito Sans', sans-serif;
}

h1 {
  font-weight: 900;
  font-size: 2.2rem;
}

h2 {
  font-weight: 700;
  font-size: 1.5rem;
}

/* H3 different and P are default */
