.proj-section {
  position: relative;
  height: 100%;
  background: linear-gradient(90deg, #ffffff 28%, rgba(255, 255, 255, 0) 0%);
  border-image: linear-gradient(
      270deg,
      rgb(235, 235, 235) 50%,
      rgb(0, 195, 255)
    )
    50;
  border-top-width: 30px;
  border-bottom-width: 30px;
  border-right-width: 0px;
  border-left-width: 30px;
  border-style: solid;
}

.proj-title {
  display: flex;
  justify-content: center;
  right: 0;
  position: absolute;
  padding-right: 2.5vw;
  z-index: 2;
}

.vid2 {
  justify-content: center;
  right: 0;
  position: absolute;
  width: 28%;
  overflow: hidden;
  height: 100%;
  object-fit: fill;
}

.proj-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 30%;
  margin-left: 1.5%;
  gap: 1.5em 1em;
  padding-bottom: 2em;
  padding-top: 2em;
}
/* ---------------- Rectangle around inner content ---------- */
.blocks {
  background-color: white;
  border-radius: 10px;
  min-height: 360px;
  width: clamp(310px, 50%, 330px);
  position: relative;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(213, 213, 213, 0.3);
}

/* -------------- inner content ---------------  */
.proj-copy-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  bottom: 0;
}

.proj-name {
  text-align: center;
  margin-bottom: 3px;
}

.proj-des {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 8%;
  text-align: center;

  /* margin-top: -1px; */
}

.photo {
  object-fit: contain;
  position: absolute;
  border-radius: 10px 10px 0 0;
  width: 100%;
  margin-top: 2em;
  height: 100px;
  top: 0;
}

.proj-tags {
  font-style: italic;
  left: 5%;
  /* display: flex; */
  position: absolute;
  bottom: 0;
  color: rgb(104, 104, 104);
}

.learn-btn {
  position: absolute;
  right: 5%;
  bottom: 8%;
  color: black;
  /* text-decoration: none; */
  /* text-decoration: underline 4px solid;
  text-underline-offset: 7px; */

  text-decoration-line: underline;
  text-underline-offset: 7px;
  text-decoration-thickness: 3px;
}

.learn-btn:hover {
  color: rgb(0, 195, 255);
  /* text-decoration: underline 4px solid;
  text-underline-offset: 7px; */
  /* background-color: #00d9ff; */
  text-decoration-line: underline;
  text-decoration-color: rgb(0, 195, 255);
  text-underline-offset: 7px;
  text-decoration-thickness: 3px;
}
/* -------------- Media query for smalls screens ------------ */
@media screen and (min-width: 556px) and (max-width: 1022px) {
  .proj-section {
    border-right-width: 30px;
  }

  .proj-title {
    top: 0;
    left: 0;
    align-items: center;
    padding-right: 0;
    padding-top: 1em;
  }

  .vid2 {
    display: none;
  }

  .proj-container {
    margin-right: 0%;
    margin-left: 0em;
    gap: 1.5em 1em;
    padding-bottom: 5em;
    padding-top: 10em;
  }
}

@media screen and (max-width: 555px) {
  /* .proj-section {
    border-width: none;
  } */

  .proj-section {
    position: relative;
    height: 100%;
    /* background: linear-gradient(180deg, #ffffff 0%, rgb(179, 214, 235) 50%); */
    border-image: linear-gradient(
        270deg,
        rgb(235, 235, 235) 50%,
        rgb(0, 195, 255)
      )
      50;
    border-top-width: 30px;
    border-bottom-width: 30px;
    border-right-width: 0px;
    border-left-width: 30px;
    border-style: none;
  }

  .vid2 {
    display: none;
  }
  .proj-title {
    top: 0;
    left: 0;
    align-items: center;
    padding-right: 0;
    padding-top: 1em;
  }
  .proj-container {
    margin-right: 0%;
    margin-left: 0em;
    gap: 1.5em 1em;
    padding-bottom: 5em;
    padding-top: 10em;
  }
}
