/* @media only screen and (min-width: 1130px) { */
.hero-section {
  width: 100vw;
  height: 100vh;
  position: relative;
}

video {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.fade {
  background-color: rgba(255, 255, 255, 0.875);
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  width: 80%;
  height: 75vh;
  /* height: 75%; */
  position: absolute;
  top: 14%;
  bottom: 20%;
  right: 10%;
  left: 10%;
  overflow: scroll;
}

/* ----------------- Inner content -------------------------- */
.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headshot {
  width: min(38vw, 230px);
  height: auto;
  border-radius: 50%;
  margin-top: 5vh;
}

.text {
  padding-left: 3em;
  padding-right: 3em;
  font-weight: 400;
  text-align: center;
}

.t2 {
  padding-left: 3em;
  padding-right: 3em;
  font-weight: 400;
  text-align: center;
}

.icons {
  width: 25px;
  height: 27px;
  color: rgb(0, 0, 0);
}

.icons:hover {
  /* color: fuchsia; */
  color: rgb(0, 195, 255);
}

.social {
  padding-top: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding-bottom: 5vh;
}
@media screen and (max-width: 555px) {
  .fade {
    background-color: rgba(255, 255, 255, 0.634);
    box-shadow: none;
    border-radius: 0px;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0%;
    bottom: 0%;
    right: 0%;
    left: 0%;
    overflow: visible;
  }
  .hero-name {
    /* font-weight: 900; */
    font-size: 1.5rem;
    /* text-align: center; */
  }

  .text {
    padding-left: 2.5em;
    padding-right: 2.5em;
    font-weight: 400;
    text-align: center;
  }

  .headshot {
    width: min(38vw, 230px);
    height: auto;
    border-radius: 50%;
    margin-top: 10vh;
  }
}

@media screen and (max-width: 375px) {
  .headshot {
    width: min(38vw, 230px);
    height: auto;
    border-radius: 50%;
    margin-top: 5vh;
  }

  .text {
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-top: 0.5vh;
  }

  .t2 {
    margin-top: 0.5vh;
    padding-left: 1em;
    padding-right: 1em;
  }
}
