@media screen and (max-width: 784px) {
  /*----------Navbar and burger menu ------------------- */

  nav {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: right;
    padding: 1em;
    position: absolute;
    z-index: 2;
    position: fixed;
    z-index: 5;
    /* background-color: rgb(34, 34, 34); */
  }

  .burger-menu {
    height: 100%;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    z-index: 5;
  }

  .burger-bar {
    width: 3.3em;
    height: 0.4em;
    background-color: rgb(123, 123, 123);
    /* background-color: rgb(255, 255, 255); */

    border-radius: 0.8em;
    z-index: 5;
  }

  .menu {
    width: 50%;
    height: 100%;
    background-color: rgb(34, 34, 34);
    right: 0;
    z-index: 3;
    position: absolute;
    position: fixed;
  }

  .mobile-items {
    display: flex;
    color: rgb(255, 255, 255);
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    bottom: 10%;
    position: absolute;
    top: 10%;
    cursor: pointer;
  }

  .mob-list {
    list-style: none;
    justify-content: space-around;
    font-weight: 500;
    font-size: 1.5rem;
  }

  .mob-item {
    margin-bottom: 5vh;
  }

  /* --------- Sliding Menu ----------- */

  .hidden {
    display: none;
  }

  .visible {
    display: inherit;
  }

  /* --------animations ---------- */

  /* clicked */
  .burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.25em, 1.05em);
    transition: ease-out 0.2s;
  }

  .burger-bar.clicked:nth-child(2) {
    transform: scale(0.01);
    transition: ease-out 0.2s;
  }

  .burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.01em, 1.01em);
    transition: ease-out 0.2s;
  }

  /* unclicked */
  .burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }
}

@media screen and (min-width: 785px) {
  .mob-nav {
    display: none;
  }
}
