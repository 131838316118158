/* https://www.w3schools.com/howto/howto_css_timeline.asp */

.timelineContainer {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* ------------ The actual timeline (the vertical ruler) ----------- */
.timelineContainer::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: rgb(235, 235, 235);
  top: 0px;
  bottom: -21px;
  left: 50%;
  margin-left: -3px;
}

/* ------------- Container around LEFT content ---------- */
.container {
  padding: 10px 40px;
  position: relative;
  width: 50%;
}

/* ------------- Sub-container around RIGHT content ----------*/
.sub-container,
.sub-containerS,
.sub-containerS1,
.sub-containerS2,
.sub-containerT {
  padding: 25px 40px;
  position: relative;
  width: 50%;
}

/* ----------- The BLACK circles on the timeline for LEFT content --------------- */
.container::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 16px;
  right: -17px;
  background-color: black;
  border: 2px solid black;
  top: 50px; /*moves left circle */
  border-radius: 50%;
  z-index: 1;
}

/* ------------ The COLOR circles on the timeline for RIGHT content ------------- */
.sub-container::after,
.sub-containerS::after,
.sub-containerS1::after,
.sub-containerT::after,
.sub-containerS2::after {
  content: '';
  position: absolute;
  width: 32px;
  height: 32px;
  right: 5px;
  top: -10px; /*moved circle */
  border-radius: 50%;
  z-index: 1;
}

.sub-containerS1::after,
.sub-containerS2::after {
  top: -73px; /*moved circle */
}

.sub-container::after,
.sub-containerS1::after {
  background-color: rgb(0, 195, 255);
  /* border: 4px solid (0, 195, 255); */
}

.sub-containerS::after,
.sub-containerS2::after {
  background-color: #7033ff;
  /* border: 4px solid #7033ff; */
}

.sub-containerT::after {
  background-color: #0264c5;
  /* border: 4px solid #0264c5; */
}

/* ------- Place the container to the LEFT ----------- */
.left {
  left: -0.7%;
}

/* -------- Place the container to the RIGHT----------- */
.right {
  left: 50%;
}

.sub-right,
.sub-rightS1,
.sub-rightS,
.sub-rightS2,
.sub-rightT {
  left: 50%; /*moved sub circle */
  margin-bottom: -10px;
}
/* ------------------ LINES ---------------------------------- */
/* ---------- LEFTLINES ----------- */

.left::before {
  content: ' ';
  border: 1px solid black;
  position: absolute;
  top: 60px;
  right: 2px;
  border-width: 1px 20px;
}

/* ---------- RIGHT LINES ----------- */
.sub-right::before,
.sub-rightS::before,
.sub-rightS1::before,
.sub-rightS2::before,
.sub-rightT::before {
  content: ' ';
  position: absolute;
  top: 5px;
  left: 15px;
}

.sub-right::before,
.sub-rightS1::before {
  border: 1px solid rgb(0, 195, 255);
  border-width: 1px 20px;
}

.sub-rightS::before,
.sub-rightS2::before {
  border: 1px solid rgb(77, 0, 128);
  border-width: 1px 20px;
}

.sub-rightS2::before,
.sub-rightS1::before {
  top: -57px;
}

.sub-rightT::before {
  border: 1px solid #0264c5;
  border-width: 1px 20px;
}

/* ------------ Fix the circle for containers on the right side ---------------- */
.right::after,
.sub-right::after,
.sub-rightS::after,
.sub-rightS1::after,
.sub-rightS2::after,
.sub-rightT::after {
  left: -16px;
}

/* --------- The actual copy/content --------------*/

/* ------- LEFT content ------- */
.content {
  padding: 20px 40px;
  position: relative;
  border-radius: 10px;
  top: 5px;
  margin-left: 30%;
  text-align: right;
}

/* ------------ RIGHT content --------- */
.sub-content,
.sub-contentS,
.sub-contentS1,
.sub-contentS2 {
  padding: 20px 40px;
  background-color: white;
  border-right: 5px black solid;
  border-bottom: 5px black solid;
  border-top: 2px black solid;
  border-left: 2px black solid;
  position: relative;
  top: -73px; /*moved content */
  border-radius: 10px;
  text-align: left;
  /* margin-bottom: 1px; */
}
.sub-contentS1,
.sub-contentS2 {
  border-top: none;
  border-left: 2px black solid;
  top: -143px; /*moved content */
  border-radius: 0px 0px 10px 10px;
  margin-bottom: -80px;
}

.date {
  font-size: small;
  color: gray;
}
.sub-title {
  color: rgb(0, 195, 255);
}

.sub-titleS {
  color: #7033ff;
}

.sub-titleT {
  color: #0264c5;
}

.dates {
  margin-top: -110px;
}

/* ------------- Media Query for smaller screens ---------  */

@media screen and (max-width: 768px) {
  /* The actual timeline (the vertical ruler) */
  .timelineContainer::after {
    left: 40px;
  }

  /* Container around content LEFT */
  .container {
    width: 100%;
  }

  /* Sub Container around content RIGHT */
  .sub-container,
  .sub-containerS1,
  .sub-containerS,
  .sub-containerT {
    margin-top: 30px;
    width: 100%;
  }
  .sub-containerS1,
  .sub-containerS2 {
    width: 100%;
  }

  /* Place the left container to the left */
  .left {
    left: 0%;
  }
  /* moved right container to left */
  .sub-right,
  .sub-rightS,
  .sub-rightS1,
  .sub-rightS2,
  .sub-rightT {
    left: 0%;
  }

  /* --------- LINES -------*/

  /*--------  LEFT lines ------- */
  .left::before {
    content: ' ';
    border: 1px solid black;
    /* position: absolute; */
    top: 50px;
    /* margin-left: auto; */
    /* padding-left: 1em; */
    display: flex;
    margin-right: 635px;
    margin-left: 10px;
    /* justify-content: left; */
    position: relative;
    /* align-items: left; */
    /* padding-left: 100em; */
    border-width: 1px 20px;
  }

  /*------  RIGHT lines--------  */
  .sub-right::before,
  .sub-rightS::before,
  .sub-rightS1::before,
  .sub-rightS2::before,
  .sub-rightT::before {
    display: none;
    /* content: ' ';
    border: 1px solid #33b3ff;
    position: absolute;
    top: -13px;
    left: 50px;
    border-width: 1px 20px; */
  }

  /* .sub-rightS::before {
    content: ' ';
    border: 1px solid #33b3ff;
    position: absolute;
    top: -13px;
    left: 50px;
    border-width: 1px 20px;
  } */

  /* .sub-rightS2::before {
    content: ' ';
    border: 1px solid #33b3ff;
    position: absolute;
    top: -13px;
    left: 50px;
    border-width: 1px 20px;
  }
  .sub-rightT::before {
    content: ' ';
    border: 1px solid #33b3ff;
    position: absolute;
    top: -13px;
    left: 50px;
    border-width: 1px 20px;
  } */

  /* ------- Fix the circle for containers on the right side --------- */

  .left::after {
    left: 30px;
  }

  .sub-right::after,
  .sub-rightS::after,
  .sub-rightS1::after,
  .sub-rightS2::after,
  .sub-rightT::after {
    left: 25px;
  }

  /* ----------- The actual COPY  content ---------- */
  .content {
    padding-left: 70px;
    padding-right: 10px;
    margin-left: 0;
    position: relative;
    border-radius: 10px;
    text-align: left;
    margin-top: -2px; /*** aligned joined statements*/
  }

  .sub-content {
    padding-left: 65px;
  }
  .sub-content,
  .sub-contentS1,
  .sub-contentS,
  .sub-contentS2 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 65px;
    padding-right: 30px;
    position: relative;
    top: -73px; /*moved content */
    border-radius: 10px;
    text-align: left;
  }

  .sub-contentS2,
  .sub-contentS1 {
    top: -135px; /*moved content */
    margin-bottom: -50px; /*******THIS FOR UNDERNEAR SPACE */
    border-radius: 0px 0px 10px 10px;
  }
  /* why is this happening */
  .sub-contentS1 {
    top: -150px; /*moved content */
    margin-bottom: -50px; /*******THIS FOR UNDERNEAR SPACE */
    border-radius: 0px 0px 10px 10px;
  }

  .date {
    font-size: small;
    color: gray;
    padding-top: 4px;
  }
  .sub-title {
    color: #33b3ff;
  }
  /* look into this  */
  .sub-containerS1::after {
    top: -83px; /*moved circle */
  }
}
