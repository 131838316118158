.navBar {
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: rgb(34, 34, 34);
  color: rgb(255, 255, 255);
  z-index: 1;
  position: absolute;
  font-weight: 500;
}

.nav-ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
  cursor: pointer;
}

li:hover {
  color: rgb(0, 195, 255);
  /* text-decoration: underline 4px solid;
  text-underline-offset: 7px;  */
  text-decoration-line: underline;
  text-decoration-color: rgb(0, 195, 255);
  text-underline-offset: 7px;
  text-decoration-thickness: 3px;
}

@media screen and (max-width: 785px) {
  .navBar {
    display: none;
  }
}
